import React, { useState } from 'react';
import Form from './Form';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';
import ThankYou from './ThankYou';

const FORM_ID = 'work_canada_job_fair';
const formConfig = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label:
      'I agree to the BUNAC privacy policy. and understand BUNAC may contact me about my event booking',
    name: 'agreed to privacy',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: "Please let us know which resort you're interested in",
    isNote: true,
    type: FIELD_TYPES.select,
    options: [
      { id: '', label: '' },
      { label: 'Blue Mountain Resort', id: 'Blue Mountain Resort' },
      { label: 'Clique Hotels and Resort', id: 'Clique Hotels and Resort' },
      {
        label: 'Four Seasons Resort Whistler',
        id: 'Four Seasons Resort Whistler',
      },
      { label: 'Panorama Mountain Resort', id: 'Panorama Mountain Resort' },
    ],
    model: DATA_MODELS.deal,
  },
];

const WorkCanadaJobFair = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="Work Canada Job Fair"
      formId={FORM_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
    />
  );
};

export default WorkCanadaJobFair;