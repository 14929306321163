import React from 'react';
import Layout, { Content } from '../../components/Layout';
import Form from '../../components/forms/WorkCanadaJobFair';

const WorkCanadaJobFair = () => {
  return (
    <Layout title={'Work Canada Job Fair'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <Form />
      </Content>
    </Layout>
  );
};

export default WorkCanadaJobFair;